import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Software Engineer, Dec 2021 - Present`}</h2>
    <p>{`At `}<a parentName="p" {...{
        "href": "https://expansiagroup.com/"
      }}>{`EXPANSIA Group, Inc.`}</a>{`, I'm architecting a full-stack Big Data web solution to select,
analyze, and track 3D-printed parts for the Department of Defense. While working there, I've improved performance of
data ingest pipeline by 5x, refactored technical debt out of a legacy web app into a modern application, and created an
internal automation tool.`}</p>
    <p>{`I've successfully worked on multiple teams using Agile methodologies, participated in code reviews, and
reached out to others when I got stuck. I've built and modified pages and components referencing design mockups, and
implemented new backend microservices to support new features. I delivered last-minute features to meet a deadline,
resulting in a very well received demo to the client.`}</p>
    <p>{`I've gained further experience with JavaScript/TypeScript and Python, tools like Docker, Kubernetes, AWS, and Azure, and
philosophies like microservices and cloud computing. I've also worked with SQL, GraphQL, Protobuf, gRPC, and Vue.js. I'm
responsible for several major refactors of growing projects, and initiated the effort to add much-needed unit testing to
the frontend codebase.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      